<template>
  <div class="position-relative table-responsive table-base">
    <!-- Modal pour ajouter une nouvelle taxe -->
    <b-modal
      id="modal-login"
      cancel-variant="outline-secondary"
      ok-title="Créer"
      cancel-title="Annuler"
      centered
      title="Transfert d'argent vers un autre compte"
      @ok="handleOk"
    >
      <validation-observer ref="registerForm">
        <b-form class="auth-register-form mt-2">
                      <h4 class="text-center text-info"> Information crediteur 	<feather-icon icon="CornerLeftUpIcon" size="18" /></h4>
          <!-- Compte crediteur -->
          <b-form-group label="" label-for="register-libelle" class="mt-2">
            <label for=""
              >Selectionner le Compte crediteur
              <span class="p-0 text-danger h6">*</span></label
            >
            <v-select
              v-model="compteCredit"
              placeholder="Selectionnez un compte"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="libelle"
              rules="required"
              :options="compteList"
            >
            </v-select>
          </b-form-group>
          <!-- montant -->
          <b-form-group label-for="register-solde">
            <label for="">Montant à envoyer <span class="p-0 text-danger h6">*</span></label>
            <validation-provider
              #default="{ errors }"
              name="solde"
              rules="required"
            >
              <b-form-input
                id="register-solde"
                @input="validateSolde"
                v-model="montant"
                type="number"
                name="register-solde"
                :state="errors.length > 0 ? false : null"
                placeholder="1000000000"
              />
              <small
                :class="valideSolde ? 'block' : 'none'"
                class="text-danger"
              >
                Veuillez entrer le montant 
              </small>
              <small :class="valeurGood ? 'block' : 'none'" class="text-danger">
                Saisie incorrecte. Vous devez renseigner un nombre avec ou sans
                virgule.<br />
                Un POINT( . ) à la place de la virgule( , )
              </small>
            </validation-provider>
          </b-form-group>

            <!-- frais d'envoi -->
          <b-form-group label-for="register-solde">
            <label for="">Frais d'envoi'<span class="p-0 text-danger h6"></span></label>
            <validation-provider
              #default="{ errors }"
              name="frais"
              rules="required"
            >
              <b-form-input
                id="register-solde"
                @input="validateSolde"
                v-model="frais"
                type="number"
                name="register-solde"
                :state="errors.length > 0 ? false : null"
                placeholder="1000000000"
              />
              <!-- <small
                :class="valideSolde ? 'block' : 'none'"
                class="text-danger"
              >
                Veuillez entrer le montant 
              </small> -->
              <small :class="valeurGood ? 'block' : 'none'" class="text-danger">
                Saisie incorrecte. Vous devez renseigner un nombre avec ou sans
                virgule.<br />
                Un POINT( . ) à la place de la virgule( , )
              </small>
            </validation-provider>
          </b-form-group>


<!-- information debiteur -->
<hr>
                    <h4 class="text-center text-success"> Information debiteur 	<feather-icon icon="CornerLeftDownIcon" size="18" /></h4>
       
        <!-- Compte debiteur -->
          <b-form-group label="" label-for="register-libelle" class="mt-2">
            <label for=""
              >Selectionner le Compte debiteur
              <span class="p-0 text-danger h6">*</span></label
            >
            <v-select
              v-model="compteDebit"
              placeholder="Selectionnez un compte"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="libelle"
              rules="required"
              :options="compteList"
            >
            </v-select>
          </b-form-group>
        </b-form>

        
      </validation-observer>
      <template #modal-footer>
        <b-button
          :disabled="state.loading === true ? true : false"
          variant="primary"
          @click.stop.prevent="handleOk"
        >
          <span v-if="state.loading === false">Valider</span>
          <b-spinner v-if="state.loading === true" label="Spinning"></b-spinner>
        </b-button>
      </template>
    </b-modal>


    <b-card no-body class="">
      <!-- Le haut du tableau contenant les barre de recherche et bouton d'ajout de nouvelle taxe -->
      <b-row class="mx-2">
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mt-1"
        >
          <label>Entrées</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button variant="primary" v-b-modal.v-b-modal.modal-login>
            <feather-icon icon="PlusIcon" class="mx-auto" />
            Nouveau compte
          </b-button>
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="6" class="mt-1">
          <div class="d-flex align-items-center justify-content-end">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="filtreCompte"
                class="d-inline-block mr-1"
                placeholder="Rechercher par : numero de compte, libelle, date d'ajout..."
              />
            </b-input-group>
          </div>
        </b-col>
      </b-row>

      <q-loader-table
        :success="state.success"
        :empty="state.empty"
        :warring="state.warring"
      />

      <b-table
        hover
        responsive
        primary-key="id"
        :per-page="perPage"
        :current-page="currentPage"
        :items="comptes"
        :fields="tableColumns"
        :filter="filtreCompte"
        show-empty
        empty-text="Aucun comptes enregistrés"
        class="bg-white text-left"
        v-if="state.success === true"
      >
        <template #cell(numero_compte)="data">
          <b-badge
            variant="light-info "
            class="text-uppercase font-weight-bolder d-inline-flex items-content-center"
          >
            <span :class="'hide-number-compte-' + data.item.id"
              >&#9900; &#9900; &#9900; &#9900;
            </span>
            <span :class="'hidden actived-number-compte-' + data.item.id"
              >{{ data.item.numero_compte }}
            </span>
            <feather-icon
              @click="qViewAccount(data.item.id)"
              :class="
                'hide-icon-compte-' +
                  data.item.id +
                  ' actived-number-compte-' +
                  data.item.id
              "
              :icon="'EyeOffIcon'"
              class="ml-50"
              size="18"
            />
            <feather-icon
              @click="qViewAccount(data.item.id)"
              :class="'hidden actived-number-compte-' + data.item.id"
              :icon="'EyeIcon'"
              class="ml-50"
              size="18"
            />
          </b-badge>
        </template>

        <template #cell(statut)>
          <b-badge
            variant="light-success"
            class="text-uppercase font-weight-bolder d-inline-flex items-content-center"
            style="font-size: 12px !important"
          >
            Activé
          </b-badge>
        </template>

        <template #cell(libelle)="data">
          <div class="py-50">
            <span variant="info" class="text-uppercase font-weight-bolder">
              {{ data.item.libelle }}
            </span>
          </div>
        </template>
        <template #cell(solde)="data">
          <span class="d-flex items-content-center">
            {{ formatMoney(data.item.solde) }}
          </span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap py-1">
            <feather-icon
              v-b-modal.modal-update
              @click="update(data.item.id)"
              icon="Edit3Icon"
              size="16"
              class="cursor-pointer"
            />

            <feather-icon
              v-if="data.item.libelle !== 'caisse'"
              @click="confirmText(data.item.id)"
              icon="TrashIcon"
              class="mx-1 cursor-pointer"
              size="16"
            />

            <!-- Dropdown -->
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <!-- <b-dropdown-item @click="editFac(data.item.id)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Modifier</span>
            </b-dropdown-item> -->
              <b-dropdown-item>
                <feather-icon icon="LockIcon" />
                <span class="align-middle ml-50">Desactiver</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>

        <template #cell(created_at)="data">
          {{ format_date(data.item.created_at) }}
        </template>
      </b-table>

      <!-- Les boutons de pagination -->
      <div class="mx-2 mb-2 pb-1">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"></span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="compteTotal"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    {{ __NEXT }}
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BModal,
  BFormInput,
  BFormGroup,
  BButton,
  VBModal,
  BForm,
  BLink,
  BFormCheckbox,
  BInputGroup,
  BInputGroupAppend,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import URL from "@/views/pages/request";
import axios from "axios";
import moment from "moment";
import { VueTelInput } from "vue-tel-input";
import {
  toast_destory,
  toast_error,
  toast_loading,
  toast_sucess,
  toast_update,
} from "@/utils/qToast";
import QLoaderTable from "@/components/__partials/loaders/qLoaderTable.vue";
export default {
  components: {
    moment,
    VueTelInput,
    vSelect,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BModal,
    BFormGroup,
    VBModal,
    BForm,
    BImg,
    BLink,
    BForm,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    QLoaderTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // validation
      config: {
        headers: {
          Accept: "application/json",
        },
      },
      selectedCompte: "",
      libelle: "",
      valideLibelle: false,

      solde: "",
      valideSolde: false,

      comptes: [],

      valeurGood: false,
      required,
      compteList: [],

      perPage: 30,
      currentPage: 1,
      compteTotal: 0,
      tableColumns: [
        { key: "numero_compte", label: "N° Compte" },
        { key: "libelle", label: "Libellé", sortable: true },
        { key: "solde", label: "Solde" },
        { key: "statut", label: "Statut" },
        { key: "created_at", label: "Date d'ajout" },
        { key: "actions" },
      ],
      state: {
        loading: false,
        success: false,
        empty: false,
        warring: false,
      },
      filtreCompte: "",
      perPageOptions: [30, 50, 100, 150, 250, 500],
      loadingDelay: 0,
      GIVE: false,
    };
  },
  computed: {
    __NEXT: function() {
      if (this.GIVE === true) {
        this.compteTotal === 0
          ? ((this.state.empty = true), (this.state.success = false))
          : ((this.state.success = true), (this.state.empty = false));
      }
      return;
    },
  },
  async mounted() {
    document.title = "Transfert";
 const config = {
        headers: {
          'Accept': 'application/json'
        },
      }
    try {
      await axios
        .get(URL.COMPTE_LIST, config)
        .then((response) => {
            this.compteList = response.data[0];
            console.log(this.compteList);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  },

  methods: {
    qViewAccount(id) {
      this.countAccout += 1;
      this.qViewAccount__on = id;

      let icon = document.querySelector(".hide-icon-compte-" + id);
      let hideAccount = document.querySelectorAll(".hide-number-compte-" + id);
      let activedAccount = document.querySelectorAll(
        ".actived-number-compte-" + id
      );

      let result = false;
      hideAccount.forEach((el) => {
        result = el.classList.toggle("hidden");
      });

      if (result) {
        activedAccount.forEach((el) => {
          el.classList.remove("hidden");
        });
        icon.classList.add("hidden");
      } else {
        activedAccount.forEach((el) => {
          el.classList.add("hidden");
        });
        icon.classList.remove("hidden");
      }
      console.log(result);
    },

    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY");
      }
    },

    formatMoney(money) {
      const formatter = new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "XOF",
        minimumFractionDigits: 2,
      });

      return formatter.format(money);
    },

    //validationStore
    validateNumeroCompte() {
      if (!this.numeroCompte) {
        this.valideNumeroCompte = true;
      } else {
        this.valideNumeroCompte = false;
      }
    },

    validateLibelle() {
      if (!this.libelle) {
        this.valideLibelle = true;
      } else {
        this.valideLibelle = false;
      }
    },

    validateSolde() {
      if (!this.solde) {
        this.valideSolde = true;
      } else {
        this.valideSolde = false;
      }

      if (!this.solde) {
        this.valideSolde = true;
      } else {
        this.valideSolde = false;
      }
      if (this.solde && this.isFloat(this.solde) === false) {
        if (this.isDigit(this.solde) === false) {
          this.valeurGood = true;
        } else {
          this.valeurGood = false;
        }
      } else {
        this.valeurGood = false;
      }
    },

    //validationUpdate
    validateEditNumeroCompte() {
      if (!this.editNumeroCompte) {
        this.valideEditNumeroCompte = true;
      } else {
        this.valideEditNumeroCompte = false;
      }
    },

    validateEditLibelle() {
      if (!this.editLibelle) {
        this.valideEditLibelle = true;
      } else {
        this.valideEditLibelle = false;
      }
    },

    validateEditSolde() {
      if (!this.editSolde) {
        this.valideEditSolde = true;
      } else {
        this.valideEditSolde = false;
      }

      if (!this.editSolde) {
        this.valideEditSolde = true;
      } else {
        this.valideEditSolde = false;
      }
      if (this.editSolde && this.isFloat(this.editSolde) === false) {
        if (this.isDigit(this.editSolde) === false) {
          this.valeurEditGood = true;
        } else {
          this.valeurEditGood = false;
        }
      } else {
        this.valeurEditGood = false;
      }
    },

    isDigit(str) {
      return /^\d+$/.test(str);
    },
    isFloat(str) {
      return /^[-+]?[0-9]+\.[0-9]+$/.test(str);
    },

    validateValeur() {
      if (!this.solde) {
        this.valideSolde = true;
      } else {
        this.valideSolde = false;
      }
      if (this.solde && this.isFloat(this.solde) === false) {
        if (this.isDigit(this.solde) === false) {
          this.valeurEditGood = true;
        } else {
          this.valeurEditGood = false;
        }
      } else {
        this.valeurEditGood = false;
      }
    },

    // confirm texrt
    confirmText(id) {
      this.$swal({
        title: `Êtes vous sûr, de supprimer le compte ${id}`,
        text: "Ce compte sera supprimer définitivement !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteCompte(id);
        }
      });
    },
    // top end
    topEnd() {
      this.$swal({
        position: "top-end",
        icon: "success",
        title: "Client enregistrée avec succès",
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    async deleteCompte(identifiant) {
      try {
        const id = {
          id: identifiant,
        };
        await axios
          .post(URL.COMPTE_DESTROY, id)
          .then((response) => {
            if (response.data) {
              toast_destory(
                this,
                "success",
                "top-right",
                "Compte supprimé avec succés !"
              );
              this.comptes = this.comptes.filter(
                (compte) => compte.id !== identifiant
              );
              this.comptes.length === 0
                ? (this.state.empty = true)
                : (this.state.success = true);
            }
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
            }
          });
      } catch (error) {
        console.log(error.type);
      }
    },
   
    async updateCompte(bvModalEvt) {
      const compte = this.compteUp;
      this.validateEditNumeroCompte();
      this.validateEditLibelle();
      this.validateEditSolde();
      if (
        this.valideEditNumeroCompte ||
        this.valideEditLibelle ||
        this.valideEditSolde
      ) {
        bvModalEvt.preventDefault();
      } else {
        if (
          compte.numero_compte != this.editNumeroCompte ||
          compte.libelle != this.editLibelle ||
          compte.solde != this.editSolde ||
          compte.description != this.editDescription
        ) {
          this.state.loading = true;
          try {
            const data = {
              id: this.id_update,
              numero_compte: this.editNumeroCompte,
              libelle: this.editLibelle,
              solde: this.editSolde,
              description: this.editDescription,
            };

            axios
              .post(URL.COMPTE_UPDATE, data, this.config)
              .then((response) => {
                if (response.data) {
                  toast_update(
                    this,
                    "success",
                    "top-right",
                    "Compte modifer avec succés !"
                  );
                  this.state.loading = false;
                  this.$bvModal.hide("modal-update");

                  this.comptes.forEach((compte) => {
                    if (compte.id === data.id) {
                      compte.numero_compte = data.numero_compte;
                      compte.libelle = data.libelle;
                      compte.solde = data.solde;
                      compte.description = data.description;
                    }
                  });
                }
              })
              .catch((error) => {
                if (error.response) {
                  console.log(error.response.data);
                }
              });
          } catch (error) {
            console.log(error.type);
            toast_error(
              this,
              "success",
              "top-right",
              "Oups, une erreur est survenue !"
            );
          }
        }
      }
    },
    // if_exist() {
    //   for (let index = 0; index < this.comptes.length; index++) {
    //     if (this.numeroCompte == this.comptes[index].numero_compte) {
    //       this.valideExiste = true;
    //       this.compte_exist =
    //         'Ce compte existe déjà! Veuillez vérifier le numero';
    //     } else {
    //       this.valideExiste = false;
    //     }
    //   }
    // },

    async handleOk(bvModalEvt) {
      this.validateNumeroCompte(), this.validateLibelle(), this.validateSolde();
      try {
        this.validateNumeroCompte();
        this.validateLibelle();
        this.validateSolde();
        // this.if_exist();

        if (
          this.valideNumeroCompte ||
          this.valideLibelle ||
          this.valideSolde ||
          this.valideExiste ||
          this.valeurGood
        ) {
          bvModalEvt.preventDefault();
          this.handleSubmit();
        } else {
          const newCompte = {
            numero_compte: this.numeroCompte,
            libelle: this.libelle,
            solde: this.solde,
            description: this.description,
          };

          this.state.loading = true;

          await axios
            .post(URL.COMPTE_CREATE, newCompte, this.config)
            .then(({ data }) => {
              toast_sucess(
                this,
                "success",
                "top-right",
                "Compte créer avec succés !"
              );
              this.comptes.unshift(data.comptes[0]);
              this.state.loading = false;
              this.$bvModal.hide("modal-login");

              //
              this.numeroCompte = "";
              this.libelle = "";
              this.solde = "";
              this.description = "";
            });
        }
      } catch (error) {
        this.state.loading = false;
        toast_error(
          this,
          "success",
          "top-right",
          "Oups, une erreur est survenue !"
        );
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.table-base {
  margin: 30px auto 0;
}

.tableau {
  box-shadow: 0px 6px 46px -21px rgba(0, 0, 0, 0.75);
}

.table-card {
  width: 100%;
  margin: auto;
  border-radius: 13px;
}

.table-card thead tr {
  border-radius: 13px;
  background-color: rgb(68, 68, 68) !important;
}

.table-card thead tr th {
  background-color: rgb(68, 68, 68) !important;
  color: white;
}

.add-btn {
  position: absolute;
  right: 0;
  top: -50px;
  background-color: #450077;
}

.none {
  display: none;
}
.block {
  display: inline-block;
}
.red:after {
  content: " *";
  color: red;
}
</style>
